<template>
  <div>
    <b-container class="spacing">
      <b-row>
        <b-col cols="4">
          <b-card
            no-body
            style="max-width: 20rem"
            img-src="@/assets/logo1.png"
            img-alt="Image"
            img-top
          >
            <b-card-body>
              <b-card-text v-if="user">
                <span v-if="isMorning()">
                  {{ morning }} {{ user.displayName }}
                </span>
                <span v-if="isEvening()">
                  {{ evening }} {{ user.displayName }}
                </span>
                <span v-if="isNight()">
                  {{ night }} {{ user.displayName }}
                </span>
                Welkom bij jouw klantenportaal
              </b-card-text>
            </b-card-body>

            <b-list-group flush>
              <b-list-group-item>
                <b-button
                  :variant="activeElement === 'INFO' ? 'dark' : 'light'"
                  @click="activeElement = 'INFO'"
                  block
                  >Mijn gegevens</b-button
                ></b-list-group-item
              >
              <b-list-group-item>
                <b-button
                  :variant="activeElement === 'HOUSES' ? 'dark' : 'light'"
                  @click="activeElement = 'HOUSES'"
                  block
                  >Mijn woningen
                </b-button></b-list-group-item
              >
              <b-list-group-item>
                <b-button
                  block
                  :variant="activeElement === 'INVOICE' ? 'dark' : 'light'"
                  @click="activeElement = 'INVOICE'"
                  >Facturen bekijken
                </b-button></b-list-group-item
              >
              <b-list-group-item>
                <b-button
                  block
                  :variant="activeElement === 'Info' ? 'dark' : 'light'"
                  :href="`mailto:${email.user}?subject=${email.subject}`"
                  >Bericht versturen
                </b-button></b-list-group-item

                
              >
               <b-list-group-item>
                <b-button
                  block
                  :variant="activeElement === 'Info' ? 'dark' : 'light'"
              @click="()=>{logOut();navigate()}"
                  >Uitloggen
                </b-button></b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col
          style="padding-top: 2rem"
          v-if="activeElement === 'INFO'"
          cols="6"
        >
          <b-row>
            <b-col>
              <label class="typo__label">Voor en Achternaam</label>
              <b-form-input
                v-if="user"
                v-model="user.displayName"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-col>

            <b-col> </b-col>
          </b-row>

          <b-row class="space">
            <b-col>
              <label class="typo__label">Email</label>
              <b-form-input
                v-if="user"
                v-model="user.email"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-col>

            <b-col>
              <label class="typo__label">Telefoonnummer</label>
              <b-form-input
                v-if="user"
                v-model="user.phoneNumber"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          style="padding-top: 2rem"
          v-if="activeElement === 'HOUSES'"
          cols="6"
        >
          <div>
            <b-card-group deck>
              <b-card
                :img-src="
                  house.images[0]
                    ? `https://back-end.mcmakelaardij.nl/static/${house.images[0].filename}`
                    : require('@/assets/no-house-image.png')
                "
                img-alt="Image"
                img-top
                tag="article"
                class="h-70"
                style="max-width: 20rem"
                v-for="house in houses"
                :key="house.house_id"
              >
                <b-card-text>
                  {{ house.adres }}
                </b-card-text>

                <!-- <b-button variant="dark" :to="`/Single/${house.house_id}`">
                  <b-icon icon="eye-fill"></b-icon> Huis bekijken
                </b-button> -->
              </b-card>
            </b-card-group>
          </div>
        </b-col>

          <b-col cols="8" style="padding-top: 2rem" v-if="activeElement === 'INVOICE'">
            <b-table responsive :items="coloredStatus" :fields="fields"> </b-table>
    
          </b-col>
   
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
export default {
  name: "Tenant",
  data() {
    return {
      user: null,
      activeElement: "INFO",
      isHidden: true,
      morning: "Goedemorgen",
      evening: "Goedemiddag",
      night: "Goedeavond",
     invoices: [],
      houses: [],
      email: {
        user: "info@mcmakelaardij.nl",
        subject: "Een bericht van de gebruikersportaal",
          },
        fields: [
          {
            label: "Factuurnummer",
            key: "number",
            sortable: true,
          },
          {
            label: "Bedrag",
            key: "bedrag",
            sortable: true,
          },

          {
            label: "Status",
            key: "status",
            sortable: true,
          },
        ],
    
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      async handler() {
        await this.getData();
        await this.getInvoices();
      },
    },
  },

  computed: {
    coloredStatus() {
      return this.invoices.map((invoice) => {
        if (invoice.status === "Betaald") {
          invoice._cellVariants = { status: "success" };
        }
        if (invoice.status === "Aanmanning") {
          invoice._cellVariants = { status: "danger" };
        }
          if (invoice.status === "Incasso") {
          invoice._cellVariants = { status: "danger" };
        }
          if (invoice.status === "Incasso") {
          invoice._cellVariants = { status: "Oninbaar" };
        }
        if (invoice.status === "Open") {
          invoice._cellVariants = { status: "info" };
        }
       
        if (invoice.status === "Herinnering") {
          invoice._cellVariants = { status: "warning" };
        }

        return invoice;
      });
    },
    filteredinvoice() {
      let searchTerm = (this.filter || "").toLowerCase();
      return this.invoices.filter((invoice) =>
        invoice.number.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    isMorning() {
      return new Date().getHours() < 12 ? true : false;
    },
    isEvening() {
      return new Date().getHours() >= 12 && new Date().getHours() <= 17
        ? true
        : false;
    },
    isNight() {
      return new Date().getHours() >= 17 && new Date().getHours() <= 24
        ? true
        : false;
    },

    cancel() {
      this.isEditing = false;
    },

    async getData() {
      const response = await axios.get(
        `https://back-end.mcmakelaardij.nl/api/house/my-houses`
      );
      this.houses = response.data;
    },

    async getInvoices() {
      const response = await axios.get(
        `https://back-end.mcmakelaardij.nl/api/invoice/owner/invoices`
      );
      this.invoices = response.data;
    },
      async logOut() {
      await firebase.auth().signOut();
            this.$router.push("/");

    },
  },
};
</script>

<style scoped>
.spacing {
  margin-top: 50px;
}
.space {
  padding-top: 1rem;
}
</style>
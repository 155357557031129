
<template>
<div>

  <UserDashboard />
  </div>
</template>

<script>

import UserDashboard from "@/components/user/UserDashboard";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    UserDashboard,
  },
  metaInfo: {
    title: "Mc makelaardij Portaal",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>